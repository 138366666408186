import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { HomeComponent } from './components/pages/home/home.component';
import { WelcomeComponent } from './components/sections/welcome/welcome.component';
import { ServicesComponent } from './components/sections/services/services.component';
import { ServicesItemComponent } from './components/sections/services/services-item/services-item.component';
import { CredentialsComponent } from './components/sections/credentials/credentials.component';
import { TeamComponent } from './components/sections/team/team.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { WizerWaysComponent } from './components/sections/wizer-ways/wizer-ways.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    WelcomeComponent,
    ServicesComponent,
    ServicesItemComponent,
    CredentialsComponent,
    TeamComponent,
    FooterComponent,
    WizerWaysComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTooltipModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
