import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  clickedLink: any;

  links: any = ['Home', 'Services', 'Credentials', 'Wizerways', 'Team'];
  constructor() { }

  ngOnInit() {
  }

  openSection(item, event) {
    console.log(item)
    const element = document.getElementById(item.toLowerCase());
    this.clickedLink = item;
    element.scrollIntoView({ behavior: "smooth" });
  }
}
