import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {
  clickedLink: any;

  constructor() { }

  ngOnInit() {
  }

  openSection(item, event) {
    const element = document.getElementById(item.toLowerCase());
    this.clickedLink = item;
    element.scrollIntoView({behavior: "smooth"});
  }

  goToSite(params) {
    window.open(params, '_blank');
  }
}
