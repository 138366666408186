import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-services-item',
  templateUrl: './services-item.component.html',
  styleUrls: ['./services-item.component.css']
})
export class ServicesItemComponent implements OnInit {
  @Input() sectionTitle

  constructor() { }

  ngOnInit() {
  }

}
