import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {
  sectionOne = "Corporate Finance";
  sectionTwo = "Project Finance";
  sectionThree = "Transaction Advisory";
  sectionFour = "Outsourcing";
  sectionFive = "Business Support";
  sectionSix = "Training"
  constructor() { }

  ngOnInit() {
  }

  goToSite(params) {
    window.open(params, '_blank');
  }

}
