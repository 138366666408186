import { Component, OnInit } from '@angular/core';
import { element } from 'protractor';

@Component({
  selector: 'app-credentials',
  templateUrl: './credentials.component.html',
  styleUrls: ['./credentials.component.css']
})
export class CredentialsComponent implements OnInit {
  clicked
  id

  constructor() { }

  ngOnInit() {
    this.clicked = false
  }

  openAccordion(param) {
    this.clicked = !this.clicked
    this.id = param
  }

}
