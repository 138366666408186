import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wizer-ways',
  templateUrl: './wizer-ways.component.html',
  styleUrls: ['./wizer-ways.component.css']
})
export class WizerWaysComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
