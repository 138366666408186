import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  clickedLink: any;
  clicked = false

  constructor() { }

  ngOnInit() {
  }

  goToSection(item) {
    this.clicked = true;
    const element = document.getElementById(item.toLowerCase());
    this.clickedLink = item;
    element.scrollIntoView({ behavior: "smooth" });
  }

}
